import { get, post, put, del } from './tools'
import api from '../common/api'

//获取上游协议模版的结算标准
export const getUpProtocolTemplateStandard = (params) =>
	get({
		url: api.getUpProtocolTemplateStandard,
		params,
	})

//获取上游协议模版的结算标准的详情
export const getUpProtocolTemplateStandardDetail = (companyId, templateId) =>
	get({
		url: api.getUpProtocolTemplateStandardDetail
			.replace('$0', companyId)
			.replace('$1', templateId),
	})
