import { get, put } from './tools'
import api from '../common/api'

//获取任务巡航详情
export const getTaskNavigate = (companyId) =>
	get({
		url: api.taskNavigate.replace('$0', companyId),
	})

//修改巡航状态
export const setFixedTaskStatus = (data) =>
	put({
		url: api.seTtaskNavigate,
		data,
	})

//获取与巡航任务相似的任务或其他任务
export const getSimilarTaskList = (taskId, similar, params) =>
	get({
		url: api.getSimilarTaskList.replace('$0', taskId).replace('$1', similar),
		params,
	})

//获取是否开启自动封装
export const getAutoPack = () =>
	get({
		url: api.getAutoPack,
	})

//修改自动封装状态
export const setAutoPack = (isOpen) =>
	put({
		url: api.setAutoPack.replace('$0', isOpen),
	})
