const initState = {
    companyId: ''
}

export default function userInfo(state = {}, action) {
    switch (action.type) {
        case "SAVE_USERINFO":
            return Object.assign({}, state, { companyId: action.data })
        case "SAVE_TASKFIXED":
            return { ...state, fixedState: action.data }
            case "SAVE_TASKCODE":
                return { ...state, taskCode: action.data }
        default:
            return state
    }
}