import { get, post, put, del } from './tools'
import api from '../common/api'

//个人版自助获取客户码及客户数量
export const getCustomerCode = (data, id) =>
	post({
		url: api.customerGetCodeV2.replace('$0', id),
		data,
	})

//查看最近一次创建的分享码详情
export const getUserLastCreateCodeInfo = () =>
	get({
		url: api.getUserLastCreateCodeInfo,
	})
