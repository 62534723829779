import React, { useState, useMemo, useEffect } from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import _ from 'lodash'
import { Modal, ConfigProvider } from 'antd'

const getValExactType = (val) =>
	Object.prototype.toString.call(val).slice(8, -1)

//传入的参数必须为plain Object 与function两类型之一，且当类型为function
//时 返回的参数可为plain Object 与function两类型之一。但最终返回的options
//类型必须为plain object 。不然options都处理为{}
const getDealOptions = (options) => {
	if (typeof options == 'function') return getDealOptions(options(LightBulb))

	if (getValExactType(options) == 'Object') return options

	return {}
}

//等hoc挂载成功后 便会把 setModalVis 赋值于此
let showFn

//控制弹框的开与关
function LightBulb(shut) {
	if (showFn) {
		showFn(!!shut)
	}
}

//包裹弹窗组件的壳hoc
function comWrapper(Component) {
	return (props) => {
		const { visible, content, destroyModalOnClose, ...restProps } = props

		const [ModalVis, setModalVis] = useState(!!visible)
		const _content = useMemo(() => content, [content])

		useEffect(() => {
			showFn = setModalVis
			let popStateHandler = () => {
				unmountComponentAtNode(document.querySelector('#renderModal'))
			}

			window.addEventListener('popstate', popStateHandler)

			return () => {
				showFn = null
				window.removeEventListener('popstate', popStateHandler)
			}
		}, [])

		useEffect(() => {
			if (!!destroyModalOnClose && !ModalVis) {
				let timerId = setTimeout(() => {
					unmountComponentAtNode(document.querySelector('#renderModal'))
					clearTimeout(timerId)
				}, 300)
			}
		}, [destroyModalOnClose, ModalVis])

		return (
			<ConfigProvider>
				<Component {...restProps} visible={ModalVis}>
					{_content}
				</Component>
			</ConfigProvider>
		)
	}
}

function SpringFrame(Options) {
	Options = getDealOptions(Options)

	let { visible, ...restOptions } = Options

	let root = document.querySelector('#renderModal')

	return {
		show: (extraOptions, component = Modal) => {
			extraOptions = getDealOptions(extraOptions)

			let Options = { ...restOptions, ...extraOptions }

			//   如果文档树中 不存在该节点 重新创建一个节点
			if (!root) {
				root = document.createElement('div')
				root.id = 'renderModal'
				;(document.body || document.documentElement).appendChild(root)
			}

			let Com = comWrapper(component)

			render(<Com {...Options} visible={true} />, root)

			// 如果每次关闭弹窗就要销毁弹框的话（配置了destroyModalOnClose）,就不必在此调用
			//LightBulb
			if (!Options?.destroyModalOnClose) {
				LightBulb(true)
			}
		},

		close: () => {
			LightBulb(false)
		},
		info: Modal.info,
		success: Modal.success,
		error: Modal.error,
		warning: Modal.warning,
		confirm: Modal.confirm,
	}
}

function toMount() {
	window.addEventListener('load', () => {
		const { show, close, info, success, error, warning, confirm } = SpringFrame(
			(LightBulb) => {
				return {
					title: <p type="primary-color">HOOSHINE</p>,
					content: <div>content here!!!</div>,
					destroyModalOnClose: true,
					onCancel: () => {
						LightBulb()
					},
					onOk: () => {
						LightBulb()
					},
					onClose: () => {
						LightBulb()
					},
				}
			}
		)

		window['$show'] = show
		window['$close'] = close
		window['$info'] = info
		window['$success'] = success
		window['$error'] = error
		window['$warning'] = warning
		window['$confirm'] = confirm
	})
}

export { SpringFrame, toMount }
