import { get, post, del } from './tools'
import api from '../common/api'

//  查询薪福社合同模版
export const speakerProtocolTemplate = (id) =>
	get({ url: api.speakerProtocolTemplate.replace('$0', id) })

//  校验是否认证薪福社
export const xinfusheCheckIsAuth = (id) =>
	get({ url: api.xinfusheCheckIsAuth.replace('$0', id) })

//  查询薪福社收款信息
export const paySpeakerXfsBank = (id) =>
	get({ url: api.paySpeakerXfsBank.replace('$0', id) })

// 查询讲课协议支付凭证
export const queryPayment = (id) =>
	get({ url: api.queryPayment.replace('$0', id) })

// 获取银行名称
export const speakerBankName = () => get({ url: api.speakerBankName })

// 取消订单
export const cancelPayment = (id) =>
	del({ url: api.queryPayment.replace('$0', id) })

// 活动登记-获取讲者参会信息和支付凭证集合
export const activityChildSpeaker = (id) =>
	get({ url: api.activityChildSpeaker.replace('$0', id) })

//公司已生效供应商服务
export const supplierActiveProtocol = (id) =>
	get({ url: api.supplierActiveProtocol.replace('$0', id) })
