import axios from '../common/http'

/**
 * 公用get请求
 * @param url       接口地址
 * @param params      接口参数
 */
export const get = ({ url, params }) =>
	axios
		.get(url, { params })
		.then((res) => Promise.resolve(res))
		.catch((res) => Promise.reject(res))

/**
 * 公用post请求
 * @param url       接口地址
 * @param data      接口参数
 */
export const post = ({ url, data, error }) =>
	axios
		.post(url, data)
		.then((res) => Promise.resolve(res.data))
		.catch((res) => Promise.reject(res))

/**
 * put 请求
 * @param url       接口地址
 * @param data      接口参数
 */
export const put = ({ url, data, error }) =>
	axios
		.put(url, data)
		.then((res) => Promise.resolve(res.data))
		.catch((res) => Promise.reject(res))

/**
 * delete 请求, 因为 delete 为 js 关键词，故取名 del
 * @param url       接口地址
 * @param data      接口参数
 */
export const del = ({ url, data, error }) =>
	axios
		.delete(url, data)
		.then((res) => Promise.resolve(res.data))
		.catch((res) => Promise.reject(res))
