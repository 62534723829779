import { get, put } from './tools'
import api from '../common/api'

//获取自定义列表内容
export const getFieldConfig = (companyId, type) =>
	get({ url: api.setFieldConfig.replace('$0', companyId).replace('$1', type) })

//设置自定义列表内容
export const setFieldConfig = (companyId, type, params) =>
	put({
		url: api.setFieldConfig.replace('$0', companyId).replace('$1', type),
		data: params,
	})
