import { get, post, put } from './tools'
import api from '../common/api'

//个人版详情
export const getPersonalCompany = () =>
	get({
		url: api.personalCompany,
	})

// //个人版订单
// export const personalOrderWx = (companyId, params) =>
// 	post({
// 		url: personalCompanyOrder.replace('$0', companyId),
// 		data: params,
// 	})

//查询个人版未付款的订单
export const unpaidPersonalOrder = (companyId) =>
	get({
		url: api.unpaidPersonalDetail.replace('$0', companyId),
	})

//个人版业务管理详情
export const getPersonalBusiness = () =>
	get({
		url: api.personalCompanyBusiness,
	})

//编辑个人版业务详情
export const updatePersonalBusiness = (data) =>
	put({
		url: api.personalCompanyBusiness,
		data,
	})
