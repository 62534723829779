import { message } from 'antd'
import request from '@common/http'

// 文件下载
class UploadFile {
	constructor() {
		this.timer = null
	}
	init(options = {}, config = {}) {
		message.destroy()
		message.loading('下载中...', 0)
		try {
			const { isAsync = false } = config
			// 是否异步下载
			if (isAsync) {
				this.asyncUpload(options, config)
			} else {
				this.uploadBolb(options, config)
			}
		} catch (err) {
			message.destroy()
		}
	}
	async pollingStatus(options, config) {
		const { duration = 1000 } = config
		if (this.timer) return
		this.timer = setInterval(async () => {
			const res = await request({
				...options,
				method: 'GET',
			})
			const resBody = res.data
			if (resBody == '') {
				message.destroy()
				clearInterval(this.timer)
				this.timer = null
				return
			}
			// 返回 url
			if (resBody?.indexOf('http') >= 0) {
				message.destroy()
				clearInterval(this.timer)
				this.timer = null
				const a = document.createElement('a')
				a.download = ''
				a.href = resBody
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
			}
		}, duration)
	}
	async asyncUpload(options, config) {
		// 先获取一下下载文件状态
		const res = await request({
			...options,
			method: 'GET',
		})
		console.log(res, 22)
		if (res.data === '0' || res.data.indexOf('http') >= 0) {
			console.log(33)
			// 后台下载中
			this.pollingStatus(options, config)
			return
		}
		await request({
			...options,
			method: 'POST',
			data: options.data || options.params,
		})
		this.pollingStatus(options, config)
	}
	async uploadBolb(options, config) {
		const res = await request({
			...options,
			responseType: 'blob',
		})
		message.destroy()
		const blob = res.data
		// FileReader主要用于将文件内容读入内存
		let reader = new FileReader()
		reader.readAsDataURL(blob)
		// onload当读取操作成功完成时调用
		reader.onload = function (e) {
			const a = document.createElement('a')
			// 获取文件名fileName
			let fileName = res.headers['content-disposition'].split('=')
			fileName = fileName[fileName.length - 1]
			fileName = fileName.replace(/"/g, '')
			fileName = decodeURIComponent(fileName)
			a.download = fileName
			a.href = e.target.result
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
		}
	}
}

const uploadApp = new UploadFile()

export default function (options, config) {
	uploadApp.init(options, config)
}
