import { get, post, put } from './tools'
import api from '../common/api'

//个人版电子签名详情
export const getPersonalSignatureInfo = () =>
	get({
		url: api.personSignInfo,
	})

//微信购买个人电子签章份数
export const fadadaPersonalWx = (params) =>
	post({
		url: api.fadadaPersonalWx,
		params,
	})
//查询个人签章和实名认证购买支付明细分页
export const fadadaPersonalPayList = (params) =>
	get({
		url: api.fadadaPersonalPayList,
		params,
	})
//查询个人版使用明细分页
export const fadadaPersonalPage = (params) =>
	get({
		url: api.fadadaPersonalPage,
		params,
	})
