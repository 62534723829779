import { get, post } from './tools'
import api from '../common/api'

//判断是否开启备案监测
export const checkMedicalAbnormalToggle = (companyId, taskId) =>
	get({
		url: api.registerDetectionToggle
			.replace('$0', companyId)
			.replace('$1', taskId),
	})

//查询备案监测检索状态
export const getMedicalAbnormalStatus = (companyId, taskId) =>
	get({
		url: api.registerDetectionStatus
			.replace('$0', companyId)
			.replace('$1', taskId),
	})

//进行备案监测检索
export const updateMedicalAbnormal = (companyId, taskId) =>
	post({
		url: api.registerDetectionStatus
			.replace('$0', companyId)
			.replace('$1', taskId),
	})

//查询备案检测的结果
export const getMedicalAbnormalResult = (companyId, taskId) =>
	get({
		url: api.registerDetectionResult
			.replace('$0', companyId)
			.replace('$1', taskId),
	})
