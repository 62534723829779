import { get, post, put } from './tools'
import api from '../common/api'

// 身份证ocr识别接口
export const idCardOCR = (params) => get({ url: api.idCardOCR, params })

//获取个人实名认证验证码接口
export const personVerifyCode = (params) =>
	get({ url: api.personVerifyCode, params })

//校验个人实名认证手机号(三要素)认证接口
export const personVerify = (params) =>
	post({
		url: api.personVerify,
		data: params,
	})

//微信购买个人实名认证次数
export const payForVerify = (params) =>
	post({ url: api.payForVerify, data: params })

// 查询支付结果
export const payResult = (id) => get({ url: api.payResult.replace('$0', id) })

// 查询实名信息
export const personVerifyInfo = () => get({ url: api.personVerifyInfo })

//获取法大大实名认证地址

export const getFadadaUrl = (params) => get({ url: api.getFadadaUrl, params })

//查询认证结果

export const getRealNameRes = () => get({ url: api.personSignInfo })
