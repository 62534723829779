import * as user from './user'
import * as company from './company'
import * as fieldConfig from './fieldConfig'
import * as speakerSign from './speakerSign'
import * as applyCenter from './applyCenter'
import * as personalCompany from './personalCompany'
import * as questionnaire from './questionnaire'
import * as medicalAbnormal from './medicalAbnormal'
import * as taskNavigate from './taskNavigate'
import * as product from './product'
import * as personCertify from './personCertify'
import * as reimburse from './reimburse'
import * as personalSignature from './personalSignature'
import * as taskAssign from './taskAssign'
import * as customerShareCode from './customerShareCode'
import * as settlementStandard from './settlementStandard'
import * as flowSystem from './flowSystem'

export const login = user.login
export const loginout = user.loginout
export const userInfo = user.userInfo

export const getProductList = product.getProductList

export const getTaskNavigate = taskNavigate.getTaskNavigate
export const setFixedTaskStatus = taskNavigate.setFixedTaskStatus
export const getSimilarTaskList = taskNavigate.getSimilarTaskList
export const getAutoPack = taskNavigate.getAutoPack
export const setAutoPack = taskNavigate.setAutoPack

export const getFieldConfig = fieldConfig.getFieldConfig
export const setFieldConfig = fieldConfig.setFieldConfig
export const speakerProtocolTemplate = speakerSign.speakerProtocolTemplate
export const xinfusheCheckIsAuth = speakerSign.xinfusheCheckIsAuth
export const paySpeakerXfsBank = speakerSign.paySpeakerXfsBank
export const queryPayment = speakerSign.queryPayment
export const speakerBankName = speakerSign.speakerBankName
export const cancelPayment = speakerSign.cancelPayment
export const activityChildSpeaker = speakerSign.activityChildSpeaker
export const supplierActiveProtocol = speakerSign.supplierActiveProtocol

export const mysupplierList = applyCenter.mysupplierList
export const mysupplierDetail = applyCenter.mysupplierDetail
export const mysupplierProtocol = applyCenter.mysupplierProtocol
export const mysupplierProtocolDetail = applyCenter.mysupplierProtocolDetail
export const mysupplierProtocolStop = applyCenter.mysupplierProtocolStop
export const mysupplierPayment = applyCenter.mysupplierPayment
export const mysupplierPaymentUser = applyCenter.mysupplierPaymentUser
export const supplyToken = applyCenter.supplyToken

export const changeCurrCompany = company.changeCurrCompany

export const getPersonalCompany = personalCompany.getPersonalCompany
// export const personalOrderWx = personalCompany.personalOrderWx
export const unpaidPersonalOrder = personalCompany.unpaidPersonalOrder
export const getPersonalBusiness = personalCompany.getPersonalBusiness
export const updatePersonalBusiness = personalCompany.updatePersonalBusiness

export const checkQuestionnaireIsPay = questionnaire.checkQuestionnaireIsPay
export const deleteAnalysePlanList = questionnaire.deleteAnalysePlanList
export const payQuestionnaireByPlan = questionnaire.payQuestionnaireByPlan

export const checkMedicalAbnormalToggle =
	medicalAbnormal.checkMedicalAbnormalToggle
export const getMedicalAbnormalStatus = medicalAbnormal.getMedicalAbnormalStatus
export const updateMedicalAbnormal = medicalAbnormal.updateMedicalAbnormal
export const getMedicalAbnormalResult = medicalAbnormal.getMedicalAbnormalResult

export const idCardOCR = personCertify.idCardOCR
export const personVerifyCode = personCertify.personVerifyCode
export const personVerify = personCertify.personVerify
export const payForVerify = personCertify.payForVerify
export const payResult = personCertify.payResult
export const personVerifyInfo = personCertify.personVerifyInfo
export const getFadadaUrl = personCertify.getFadadaUrl
export const getRealNameRes = personCertify.getRealNameRes

export const addReimburse = reimburse.addReimburse
export const getReimburseList = reimburse.getReimburseList
export const getReimburseDetail = reimburse.getReimburseDetail
export const updateReimburseStatus = reimburse.updateReimburseStatus
export const getReimburseApproval = reimburse.getReimburseApproval
export const reimburseTemplateList = reimburse.reimburseTemplateList
export const reimburseTemplateDetail = reimburse.reimburseTemplateDetail
export const getReimburseApprover = reimburse.getReimburseApprover
export const getReimburseCompanyApprover = reimburse.getReimburseCompanyApprover
export const getMyReimburseApproval = reimburse.getMyReimburseApproval
export const deleteReimburse = reimburse.deleteReimburse
export const reimburseApprovalExist = reimburse.reimburseApprovalExist
export const getReimburseSignature = reimburse.getReimburseSignature
export const reimburseElectronicExist = reimburse.reimburseElectronicExist
export const reimburseDownloadPdf = reimburse.reimburseDownloadPdf
export const getReimburseProposer = reimburse.getReimburseProposer
export const reimburseAdminReject = reimburse.reimburseAdminReject
export const reimburseUpload = reimburse.reimburseUpload
export const updateReimburse = reimburse.updateReimburse
export const getReimburseTemplateFieldCommon =
	reimburse.getReimburseTemplateFieldCommon
export const usableReimburseTemplate = reimburse.usableReimburseTemplate
export const disableReimburseTemplate = reimburse.disableReimburseTemplate
export const addReimburseTemplate = reimburse.addReimburseTemplate
export const deleteReimburseTemplate = reimburse.deleteReimburseTemplate
export const getReimburseTemplateByCode = reimburse.getReimburseTemplateByCode
export const getReimburseTemplateCreators =
	reimburse.getReimburseTemplateCreators
export const updateReimburseTemplate = reimburse.updateReimburseTemplate
export const copyReimburseTemplate = reimburse.copyReimburseTemplate
export const getReimburseCustomField = reimburse.getReimburseCustomField
export const updateReimburseCustomField = reimburse.updateReimburseCustomField

export const getPersonalSignatureInfo =
	personalSignature.getPersonalSignatureInfo
export const fadadaPersonalWx = personalSignature.fadadaPersonalWx
export const fadadaPersonalPayList = personalSignature.fadadaPersonalPayList
export const fadadaPersonalPage = personalSignature.fadadaPersonalPage

export const getProjectGroupSetting = taskAssign.getProjectGroupSetting
export const getTaskCompleteSetting = taskAssign.getTaskCompleteSetting
export const getProjectGroupSettingForTaskID =
	taskAssign.getProjectGroupSettingForTaskID
export const getTaskAssignmentPersonalItems =
	taskAssign.getTaskAssignmentPersonalItems
export const getTaskBudgetProjectGroupInfo =
	taskAssign.getTaskBudgetProjectGroupInfo

export const getCustomerCode = customerShareCode.getCustomerCode
export const getUserLastCreateCodeInfo =
	customerShareCode.getUserLastCreateCodeInfo

export const getUpProtocolTemplateStandard =
	settlementStandard.getUpProtocolTemplateStandard
export const getUpProtocolTemplateStandardDetail =
	settlementStandard.getUpProtocolTemplateStandardDetail

export const getKindTypes = flowSystem.getKindTypes
