import { get, post } from './tools'
import api from '../common/api'

// 用户登录
export const login = (params) => post({ url: api.login, data: params })

//用户注销
export const loginout = () => post({ url: api.logout })

//用户信息
export const userInfo = () =>
	get({
		url: api.userInfo,
	})
