export const imagePng = 'image/png';
export const imageJpg = 'image/jpg';
export const imageJpeg = 'image/jpeg';
export const imageHeic = 'image/heic';
export const imageBmp = 'image/bmp';
export const filePdf = 'application/pdf';
export const imageValidTypes = [imagePng, imageJpg, imageJpeg, imageHeic];

export const isImageHeic = (file) => {
    if (file.type === imageHeic) {
        return true;
    }
    // 文件类型不存在时根据后缀判断
    if (!file.type) {
        const type = file.name.split('.').pop().toLocaleLowerCase();
        return type === 'heic';
    }
    return false;
};

export class ImageFile {
    constructor(file) {
        this.setFile(file);
    }

    setFile = (file) => {
        this.file = file;
    };

    /**
     * 校验图片大小
     * @param {number} validSize 图片大小
     * @returns {boolean}
     */
    isValidSize(validSize) {
        return this.file.size / 1024 / 1024 < validSize;
    }
    /**
     * 校验图片类型
     * @param {Array<string>} [validTypes=['image/png', 'image/jpg', 'image/jpeg', 'image/heic']] 图片类型
     * @returns {boolean}
     */
    isValidType(validTypes = imageValidTypes) {
        if (this.file.type) {
            return validTypes.includes(this.file.type);
        }
        const hasHeic = validTypes.includes(imageHeic);
        if (hasHeic) {
            return isImageHeic(this.file);
        }
        return false;
    }

    isValidName() {
        const fileName = this.file.name;
        return !(fileName.indexOf('#') > -1 || fileName.indexOf('%') > -1);
    }
    isValidNameLimit(validNameLimit = 255) {
        const fileName = this.file.name;
        const chinRegexpRule = /[\u2E80-\u9FFF]/g;
        const match = fileName.match(chinRegexpRule) ?? [];
        const totalLetterCount = fileName.length;
        return !(match.length * 4.3 + (totalLetterCount - match.length) * 1.2 > validNameLimit);
    }
    isDefaultValid(options = {}) {
        const {
            validTypes,
            validTypesMessage = '图片格式仅支持JPG、JPEG、PNG',
            validSize = 20,
            validNameLimit = 255
        } = options;
        if (!this.isValidType(validTypes)) {
            return {
                message: validTypesMessage,
                type: 'type',
                success: false
            };
        }
        if (!this.isValidSize(validSize)) {
            return {
                message: `图片大小限制${validSize}M`,
                type: 'size',
                success: false
            };
        }
        if (!this.isValidName()) {
            return {
                message: '文件名请勿携带特殊符号',
                type: 'name',
                success: false
            };
        }
        if (!this.isValidNameLimit(validNameLimit)) {
            return {
                message: `文件名长度限制${validNameLimit}字符`,
                type: 'nameLimit',
                success: false
            };
        }
        return {
            success: true
        };
    }
}
