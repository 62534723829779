import { get, post, del } from './tools'
import api from '../common/api'

//检查历程是否需要购买
export const checkQuestionnaireIsPay = (params) =>
	post({
		url: api.checkIsPay,
		data: params,
	})

//删除关联的历程
export const deleteAnalysePlanList = (params) =>
	del({
		url: api.analysePlanListDel
			.replace('$0', params[0])
			.replace('$1', params[1])
			.replace('$2', params[2]),
	})

//按问卷历程购买
export const payQuestionnaireByPlan = (id, params) =>
	post({
		url: api.payByplan.replace('$0', id),
		data: params,
	})
