import { Modal } from 'antd';
import React from 'react';
import { logout } from './utils';

let isErrorModalShowing = false;

(function ($) {
    // 备份jquery的ajax方法
    const _ajax = $.ajax;
    // 重写jquery的ajax方法
    $.AJAX = $.ajax = function (opt) {
        // 备份opt中beforeSend方法
        if (opt.url && opt.url.includes('undefined')) {
            return;
        } // 判断url中是否有underfind这种 有的话直接返回 不进行校验
        const fn = {
            data: {},
            beforeSend(request) {},
            error(data) {}
        };
        if (opt.beforeSend) {
            fn.beforeSend = opt.beforeSend;
        }
        if (opt.data) {
            fn.data = opt.data;
        }
        if (opt.success) {
            fn.success = opt.success;
        }
        // 扩展增强处理
        const _opt = $.extend(opt, {
            // data数据扩展
            data: fn.data,
            beforeSend(request) {
                // beforeSend方法增强处理
                if (window.localStorage.AUTHORIZATION)
                    request.setRequestHeader('Authorization', window.localStorage.AUTHORIZATION);
                if (window.localStorage.CLIENT) request.setRequestHeader('channel', window.localStorage.CLIENT);
                if (window.localStorage.COMPANYID) request.setRequestHeader('COMPANYID', window.localStorage.COMPANYID);
                if (window.localStorage.COMPANYTYPE) {
                    request.setRequestHeader('COMPANYTYPE', window.localStorage.COMPANYTYPE == 1 ? 2 : 32); // 前端和服务端值是不一样的，这里转一下
                }
                request.setRequestHeader('ct', 23);
                fn.beforeSend(request);
            },
            error:
                opt.error ||
                function (data, type, two) {
                    // 标识了登录页面需要刷新的，不弹出错误提示
                    const reloadLogin = window.sessionStorage.getItem('reloadLogin');
                    if (reloadLogin === 'true') {
                        return;
                    }
                    // 含有多个错误时的处理
                    if (data.responseJSON) {
                        const { errors } = data.responseJSON;
                        if (errors) {
                            const title = `含有${errors.length}个错误`;
                            Modal.error({
                                title,
                                content: React.createElement(
                                    'div',
                                    null,
                                    _.map(errors, (error, i) => {
                                        const msg = `${i + 1}:${error.errMsg} `;
                                        return React.createElement('p', { key: i }, msg);
                                    })
                                )
                            });
                            return;
                        }
                    }

                    // 根据返回值决定是否弹出错误提示框,按原逻辑,默认在本处弹出
                    if (false != fn.error(data)) {
                        console.log(data);
                        // 暂时先这么处理,单单处理类别信息完善
                        if (
                            data.responseJSON &&
                            data.responseJSON.message &&
                            data.responseJSON.message === '产品类别信息错误，请先完善产品类别信息'
                        ) {
                            Modal.error({
                                title:
                                    data.responseJSON && data.responseJSON.message
                                        ? data.responseJSON.message
                                        : '网络繁忙,请稍后再试',
                                onOk() {
                                    window.location.href = '/#/products/settings/completecategories';
                                },
                                okText: '前去设置'
                            });
                        } else {
                            if (!isErrorModalShowing) {
                                // 只允许同时显示一个错误框
                                isErrorModalShowing = true;
                                let title = '网络繁忙,请稍后再试';
                                if (data.responseJSON && data.responseJSON.message) {
                                    if (data.responseJSON.message == 'Access Denied') {
                                        title = '请重新登录';
                                        Modal.error({
                                            title,
                                            onOk() {
                                                isErrorModalShowing = false;
                                                logout();
                                            }
                                        });
                                    } else {
                                        if (data.responseJSON.fieldMsgs) {
                                            title = data.responseJSON.fieldMsgs[0].message;
                                            Modal.error({
                                                title,
                                                onOk() {
                                                    isErrorModalShowing = false;
                                                }
                                            });
                                        } else {
                                            const errorCode = data.responseJSON.errorCode
                                                ? data.responseJSON.errorCode
                                                : null;

                                            title = data.responseJSON.message;

                                            const showLine = title.split('（').length !== 1;
                                            const newTitle = title.split('（')[0];

                                            const content = showLine ? title.split('（')[1].split('）')[0] : '';
                                            if (errorCode == 100405) {
                                                Modal.error({
                                                    title: showLine ? newTitle : title,
                                                    content: showLine ? (
                                                        <span style={{ color: '#999', fontSize: 12 }}>
                                                            （{content}）
                                                        </span>
                                                    ) : (
                                                        ''
                                                    ),
                                                    onOk() {
                                                        isErrorModalShowing = false;
                                                    }
                                                });
                                            } else {
                                                Modal.error({
                                                    title: '温馨提示',
                                                    content: title,
                                                    okText: '知道了',
                                                    onOk() {
                                                        isErrorModalShowing = false;
                                                    }
                                                });
                                            }
                                        }
                                    }
                                } else if (data.status == 403) {
                                    title = data.responseText;
                                    Modal.error({
                                        title,
                                        onOk() {
                                            isErrorModalShowing = false;
                                        }
                                    });
                                } else if (data.status == 401) {
                                    title = data.responseText;
                                    Modal.error({
                                        title,
                                        onOk() {
                                            isErrorModalShowing = false;
                                            logout();
                                        }
                                    });
                                } else {
                                    if (data.status == 0) {
                                        isErrorModalShowing = false;
                                        return;
                                    }
                                    {
                                        Modal.error({
                                            title: data.status === 504 ? '系统正在处理中，请稍后' : title,
                                            onOk() {
                                                isErrorModalShowing = false;
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
        });
        _ajax(_opt);
    };
})($);
