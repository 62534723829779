import { get, post, put } from './tools'
import api from '../common/api'

// 我的应用列表
export const mysupplierList = () => get({ url: api.mysupplierList })

//  供应商服务信息详情
export const mysupplierDetail = (id) =>
	get({ url: api.mysupplierDetail.replace('$0', id) })

//  协议列表
export const mysupplierProtocol = (id, params) =>
	get({ url: api.mysupplierProtocol.replace('$0', id), params })

//  协议详情
export const mysupplierProtocolDetail = (id) =>
	get({ url: api.mysupplierProtocolDetail.replace('$0', id) })

// 终止协议
export const mysupplierProtocolStop = (id) =>
	put({
		url: api.mysupplierProtocolStop.replace('$0', id),
	})

//查询讲课协议支付记录分页
export const mysupplierPayment = (params) =>
	get({
		url: api.mysupplierPayment,
		params,
	})
//查询讲课协议支付记录创建人
export const mysupplierPaymentUser = () =>
	get({
		url: api.mysupplierPaymentUser,
	})

//获取应用跳转token
export const supplyToken = () =>
	get({
		url: api.supplyToken,
	})
