import { get } from './tools'
import api from '../common/api'

//查询项目组设置
export const getProjectGroupSetting = (id, versionId) =>
	get({
		url: api.getProjectGroupSetting.replace('$0', id).replace('$1', versionId),
	})

//按任务查询项目组设置
export const getProjectGroupSettingForTaskID = (id, versionId) =>
	get({
		url: api.getProjectGroupSettingForTaskID
			.replace('$0', id)
			.replace('$1', versionId),
	})

//查询当前任务分配方式
export const getTaskCompleteSetting = (id) =>
	get({
		url: api.getTaskCompleteSetting.replace('$0', id),
	})

//推广任务-通过指派用户,获取个人的可以指派的项目
export const getTaskAssignmentPersonalItems = (taskId, userId) =>
	get({
		url: api.taskAssignmentPersonalItems
			.replace('$0', taskId)
			.replace('$1', userId),
	})

//按任务查询本任务的预算项目组详情
export const getTaskBudgetProjectGroupInfo = (id) =>
	get({
		url: api.getTaskBudgetProjectGroupInfo.replace('$0', id),
	})
