import { get, post, put, del } from './tools'
import api from '../common/api'

//报销付款列表
export const getReimburseList = (params) =>
	get({
		url: api.reimburse,
		params,
	})

//添加报销付款
export const addReimburse = (data) =>
	post({
		url: api.reimburse,
		data,
	})

//报销付款详情
export const getReimburseDetail = (id) =>
	get({
		url: api.reimburseDetail.replace('$0', id),
	})

//删除报销付款
export const deleteReimburse = (id) =>
	del({
		url: api.reimburseDetail.replace('$0', id),
	})

//编辑报销付款
export const updateReimburse = (params, id) =>
	put({
		url: api.reimburseDetail.replace('$0', id),
		data: params,
	})

//模版列表
export const reimburseTemplateList = (params) =>
	get({
		url: api.reimburseTemplate,
		params,
	})

//添加模板
export const addReimburseTemplate = (data) =>
	post({
		url: api.reimburseTemplate,
		data,
	})

//启用模板
export const usableReimburseTemplate = (id) =>
	put({
		url: api.usableReimburseTemplate.replace('$0', id),
	})

//禁用模板
export const disableReimburseTemplate = (id) =>
	put({
		url: api.disableReimburseTemplate.replace('$0', id),
	})

//审批日志列表
export const getReimburseApproval = (params, id) =>
	get({
		url: api.reimburseApproval.replace('$0', id),
		params,
	})

//模板详情
export const reimburseTemplateDetail = (id) =>
	get({
		url: api.reimburseTemplateDetail.replace('$0', id),
	})

//编辑模板
export const updateReimburseTemplate = (id, data) =>
	put({
		url: api.reimburseTemplateDetail.replace('$0', id),
		data,
	})

//复制模板
export const copyReimburseTemplate = (code, name) =>
	post({
		url: api.copyReimburseTemplate.replace('$0', code),
		data: name,
	})

//删除模板
export const deleteReimburseTemplate = (id) =>
	del({
		url: api.reimburseTemplateDetail.replace('$0', id),
	})

//查询模板
export const getReimburseTemplateByCode = (code) =>
	get({
		url: api.reimburseTemplateByCode.replace('$0', code),
	})

//查询模板创建人
export const getReimburseTemplateCreators = () =>
	get({
		url: api.reimburseTemplateCreators,
	})

//申请人列表-筛选条件
export const getReimburseProposer = (params) =>
	get({
		url: api.reimburseProposer,
		params,
	})

//审批人列表-筛选条件
export const getReimburseApprover = () =>
	get({
		url: api.reimburseApprover,
	})

//审批人列表-创建时的审批人选择列表
export const getReimburseCompanyApprover = () =>
	get({
		url: api.reimburseCompanyApprover,
	})

//更新状态
export const updateReimburseStatus = (params) =>
	put({
		url: api.reimburseStatus,
		data: params,
	})

//我的审批列表
export const getMyReimburseApproval = (params) =>
	get({
		url: api.reimburseApprovalMine,
		params,
	})

//是否存在待审批
export const reimburseApprovalExist = () =>
	get({
		url: api.reimburseApprovalExist,
	})

//获取签字随机码
export const getReimburseSignature = () =>
	get({
		url: api.reimburseSignature,
	})

//当前是否是电子审批
export const reimburseElectronicExist = (params) =>
	post({
		url: api.reimburseElectronicExist,
		data: params,
	})

//下载pdf
export const reimburseDownloadPdf = (params) =>
	post({
		url: api.reimburseDownloadPdf,
		data: params,
	})

//超管驳回
export const reimburseAdminReject = (params, id) =>
	put({
		url: api.reimburseAdminReject.replace('$0', id),
		data: params,
	})

//上传excel
export const reimburseUpload = (params) =>
	post({
		url: api.reimburseUpload,
		data: params,
	})

//常用字段
export const getReimburseTemplateFieldCommon = () =>
	get({
		url: api.reimburseTemplateFieldCommon,
	})

//获取自定义列表内容
export const getReimburseCustomField = () =>
	get({
		url: api.reimburseCustomField,
	})

//修改自定义列表内容
export const updateReimburseCustomField = (data) =>
	put({
		url: api.reimburseCustomField,
		data,
	})
