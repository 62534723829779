import { combineReducers } from 'redux';
import approveCount from './approveCount';
import companyID from './companyID';
import personalCompanyInfo from './companyInfo';
import companyType from './companyType';
import MessageCount from './messageCount';
import promptStatus from './promptStatus';
import registerDetection from './registerDetection';
import saveInfo from './saveInfo';
import signCount from './signCount';
import status from './status';
import Template from './Template';
import timeInfo from './timeInfo';
import userInfo from './userInfo';
import userName from './userName';
const reduce = combineReducers({
    userInfo,
    personalCompanyInfo,
    MessageCount,
    userName,
    companyID,
    timeInfo,
    Template,
    status,
    signCount,
    approveCount,
    promptStatus,
    pageMes: saveInfo,
    companyType,
    registerDetection
});

export default reduce;
